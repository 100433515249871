// src/assessment/Assessment.js
import React, { useState, useEffect, useCallback } from 'react';
import api from '../../api';
import { 
  Box, 
  Typography, 
  Container, 
  LinearProgress, 
  Alert, 
  TextField,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Fade,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { sections, scoreMapping } from "./AssessmentQuestions";
import AssessmentSection from './AssessmentSection';
import FormNavigation from './FormNavigation';
import ResultComponent from './ResultComponent';
import "../../styles/styles.css";
import "../../styles/tokens.css";
import "../../styles/Assessment.css";

const Assessment = () => {
  const [state, setState] = useState({
    answers: {},
    currentSection: 0,
    submitted: false,
    error: null,
    sectionScores: [],
    overallScore: 0,
    firstName: "",
    lastName: "",
    isLoading: false
  });
  const isFullScreen = window.innerWidth > 600;

  // Load saved assessment data
  useEffect(() => {
    const loadSavedAssessment = () => {
      const savedSubmitted = localStorage.getItem('assessmentSubmitted');
      const savedResult = localStorage.getItem('assessmentResult');
      
      if (savedSubmitted === 'true' && savedResult) {
        const parsedResult = JSON.parse(savedResult);
        setState(prev => ({
          ...prev,
          firstName: parsedResult.firstname,
          lastName: parsedResult.lastname,
          submitted: true,
          sectionScores: parsedResult.sectionScores,
          overallScore: parsedResult.overallScore
        }));
      }
    };

    loadSavedAssessment();
  }, []);

  const handleRetakeAssessment = useCallback(() => {
    localStorage.removeItem('assessmentSubmitted');
    localStorage.removeItem('assessmentResult');
    setState({
      answers: {},
      currentSection: 0,
      submitted: false,
      error: null,
      sectionScores: [],
      overallScore: 0,
      firstName: "",
      lastName: "",
      isLoading: false
    });
  }, []);

  const calculateSectionScores = useCallback(() => {
    const sectionScores = sections.map((section, index) => {
      const sectionAnswers = state.answers[index] || {};
      const totalScore = Object.values(sectionAnswers).reduce((acc, item) => {
        const answerScore = scoreMapping[item.type][item.answer] || 0;
        return acc + answerScore;
      }, 0);

      return { 
        section: section.title, 
        totalScore,
        interpretation: getScoreInterpretation(totalScore)
      };
    });

    const overallTotalScore = sectionScores.reduce((total, section) => total + section.totalScore, 0);
    const calculatedResult = getOverallInterpretation(overallTotalScore);

    return { sectionScores, overallTotalScore, calculatedResult };
  }, [state.answers]);

  const getScoreInterpretation = (score) => {
    if (score <= -4) return "Significant room for growth";
    if (score <= -2) return "Some improvement needed";
    if (score <= 2) return "Balanced awareness";
    if (score <= 4) return "Good progress";
    return "Excellent awareness";
  };

  const getOverallInterpretation = (score) => {
    if (score < -5) return "Strong survival instinct; high resistance to change.";
    if (score > 5) return "Low survival instinct; open to change.";
    return "Moderate survival instinct; some resistance to change.";
  };

  const handleAnswer = useCallback((sectionIndex, question, answer) => {
    setState(prev => ({
      ...prev,
      answers: {
        ...prev.answers,
        [sectionIndex]: {
          ...prev.answers[sectionIndex],
          [question]: { 
            answer, 
            question, 
            type: sections[sectionIndex].questions.find(q => q.question === question).type 
          },
        },
      },
      error: null
    }));
  }, []);

  const validateSection = useCallback((sectionIndex) => {
    const currentAnswers = state.answers[sectionIndex] || {};
    return sections[sectionIndex]?.questions.every(
      (q) => currentAnswers[q.question] !== undefined
    );
  }, [state.answers]);

  const handleNavigation = useCallback((direction) => {
    setState(prev => {
      if (direction === 'next') {
        if (!validateSection(prev.currentSection)) {
          return { ...prev, error: 'Please answer all questions before proceeding.' };
        }
        return {
          ...prev,
          currentSection: Math.min(prev.currentSection + 1, sections.length - 1),
          error: null
        };
      }
      return {
        ...prev,
        currentSection: Math.max(prev.currentSection - 1, 0),
        error: null
      };
    });
  }, [validateSection]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    
    // Validate all sections and personal info
    const allSectionsValid = sections.every((_, index) => validateSection(index));
    if (!allSectionsValid || !state.firstName || !state.lastName) {
      setState(prev => ({
        ...prev,
        error: 'Please complete all sections and provide your name before submitting.'
      }));
      return;
    }

    setState(prev => ({ ...prev, isLoading: true, error: null }));

    try {
      const { sectionScores, overallTotalScore, calculatedResult } = calculateSectionScores();
      
      const transformedAnswers = sections.map((section, sectionIndex) => ({
        section: section.title,
        questions: Object.entries(state.answers[sectionIndex] || {}).map(([question, answer]) => ({
          question,
          answer: answer.answer,
          type: answer.type
        })),
        sectionScore: sectionScores[sectionIndex].totalScore
      }));

      // Save to localStorage
      localStorage.setItem('assessmentSubmitted', 'true');
      localStorage.setItem('assessmentResult', JSON.stringify({
        firstname: state.firstName,
        lastname: state.lastName,
        sectionScores,
        overallScore: overallTotalScore,
        result: calculatedResult
      }));

      // Submit to API
      const response = await api.post('/api/assessment/submit', {
        userId: '60d0fe4f5311236168a109ca',
        name: `${state.firstName} ${state.lastName}`,
        answers: transformedAnswers,
        totalScore: overallTotalScore,
        Result: calculatedResult
      });

      if (response.status === 201) {
        setState(prev => ({
          ...prev,
          submitted: true,
          sectionScores,
          overallScore: overallTotalScore,
          isLoading: false
        }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error submitting assessment:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to submit assessment. Please try again.',
        isLoading: false
      }));
    }
  }, [state.answers, state.firstName, state.lastName, calculateSectionScores, validateSection]);

  const calculateProgress = useCallback(() => {
    const totalQuestions = sections.reduce((sum, section) => sum + section.questions.length, 0);
    const answeredQuestions = Object.values(state.answers).reduce(
      (sum, sectionAnswers) => sum + Object.keys(sectionAnswers).length,
      0
    );
    return (answeredQuestions / totalQuestions) * 100;
  }, [state.answers]);

  if (state.submitted) {
    return (
      <Fade in timeout={800}>
        <Box sx={{ 
          backgroundColor: "#0000", 
          minHeight: "100vh", 
          py: 4, 
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 4
        }}>
          <ResultComponent 
            overallScore={state.overallScore} 
            firstName={state.firstName} 
            handleRetakeAssessment={handleRetakeAssessment}
            sectionScores={state.sectionScores}
            archetype={state.overallScore < -5 ? "Defender" : state.overallScore > -5 && state.overallScore < 5 ? "Explorer" : "Innovator"}
          />
        </Box>
      </Fade>
    );
  }

  return (
    <Box sx={{ backgroundColor: "#0000", minHeight: "100vh", py: 4, mt: 4 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paper 
            elevation={3}
            sx={{ 
              p: { xs: 2, md: 4 }, 
              borderRadius: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              backdropFilter: 'blur(10px)'
            }}
          >
            <Typography 
              variant="h4"
              sx={{ 
                fontWeight: 'bold',
                mb: 3,
                background: 'linear-gradient(45deg, #B388FF, #80CBC4)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                textShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              }}
            >
              Survival Instinct Awareness Assessment (SIAA)
            </Typography>

            <Typography sx={{ mb: 3, color: '#FFFFFF', fontSize: '1.1rem', lineHeight: 1.6 }}>
              Welcome to the Survival Instinct Awareness Assessment. This survey helps you understand how your instinctual need to protect and defend your self-identity influences your creativity, adaptability, and personal growth. By identifying these instincts, you can become more aware of your behaviors and thought processes, enabling a transition from a stagnant identity to a more fluid flow identity.
            </Typography>

            {/* Progress Indicator */}
            {isFullScreen &&(
            <Box sx={{ mb: 4 }}>
              <Stepper 
                activeStep={state.currentSection} 
                alternativeLabel
                sx={{
                  '& .MuiStepLabel-label': {
                    color: '#E0E0E0',
                    fontSize: '0.9rem',
                    '&.Mui-active': {
                      color: '#B388FF',
                      fontWeight: 'bold',
                    },
                    '&.Mui-completed': {
                      color: '#80CBC4',
                    },
                  },
                  '& .MuiStepIcon-root': {
                    color: 'rgba(179, 136, 255, 0.3)',
                    '&.Mui-active': {
                      color: '#B388FF',
                    },
                    '&.Mui-completed': {
                      color: '#80CBC4',
                    },
                  },
                }}
              >
                {sections.map((section, index) => (
                  <Step key={index}>
                    <StepLabel>{section.title}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            )}
            {/* Error Message */}
            {state.error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 3,
                  backgroundColor: 'rgba(244, 67, 54, 0.1)',
                  color: '#FFFFFF',
                  '& .MuiAlert-icon': {
                    color: '#FF8A80'
                  }
                }}
                onClose={() => setState(prev => ({ ...prev, error: null }))}
              >
                {state.error}
              </Alert>
            )}

            {/* Name Fields */}
            {state.currentSection === 0 && (
              <Box sx={{ mb: 4, display: 'flex', gap: 2 }}>
                <TextField
                  label="First Name"
                  value={state.firstName}
                  onChange={(e) => setState(prev => ({ ...prev, firstName: e.target.value }))}
                  required
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(179, 136, 255, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B388FF',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#B388FF',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#E0E0E0',
                    },
                    '& input': {
                      color: '#FFFFFF',
                    },
                  }}
                />
                <TextField
                  label="Last Name"
                  value={state.lastName}
                  onChange={(e) => setState(prev => ({ ...prev, lastName: e.target.value }))}
                  required
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(179, 136, 255, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B388FF',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#B388FF',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#E0E0E0',
                    },
                    '& input': {
                      color: '#FFFFFF',
                    },
                  }}
                />
              </Box>
            )}
            {!isFullScreen && (
            <Typography
            sx = {{
              mb:1.3,
              color: '#8e24aa', 
              fontSize: '1.1rem', 
              lineHeight: 1,
              textAlign: "center"
            }}>
              1 - Strongly Disagree 
            </Typography>
          )}
          {!isFullScreen && (
            <Typography
            sx = {{
              mb:1.3,
              color: '#b0bec5', 
              fontSize: '1.1rem', 
              lineHeight: 1,
              textAlign: "center"
            }}>
               3 - Neutral 
            </Typography>
          )}
          {!isFullScreen && (
            <Typography
            sx = {{
              mb:2,
              color: '#43a047', 
              fontSize: '1.1rem', 
              lineHeight: 1,
              textAlign: "center"
            }}>
             5 - Strongly Agree
            </Typography>
          )}

            {/* Assessment Section */}
            <AnimatePresence mode="wait">
              <motion.div
                key={state.currentSection}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
              >
                <AssessmentSection
                  section={sections[state.currentSection]}
                  answers={state.answers[state.currentSection] || {}}
                  onAnswer={handleAnswer}
                  currentSection={state.currentSection}
                />
              </motion.div>
            </AnimatePresence>

            {/* Navigation */}
            <FormNavigation
              currentSection={state.currentSection}
              totalSections={sections.length}
              onBack={() => handleNavigation('back')}
              onNext={() => handleNavigation('next')}
              onSubmit={handleSubmit}
              isLoading={state.isLoading}
            />

            {/* Progress Bar */}
            <Box sx={{ mt: 4 }}>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: '#9E9E9E',
                  display: 'block',
                  mb: 1 
                }}
              >
                Overall Progress: {Math.round(calculateProgress())}%
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={calculateProgress()} 
                sx={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: 'rgba(179, 136, 255, 0.1)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#B388FF',
                  },
                }}
              />
            </Box>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Assessment;
