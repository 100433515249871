import React, { useEffect, useState } from 'react';
import { Box, FormControl, RadioGroup, FormControlLabel, Radio, Typography, Paper } from '@mui/material';

const Question = ({ question, answer, onAnswer, isActive }) => {
  const [selectedValue, setSelectedValue] = useState(answer ? answer.toString() : '');

  useEffect(() => {
    setSelectedValue(answer ? answer.toString() : '');
  }, [answer]);

  const isFullScreen = window.innerWidth > 600;

  const scale = [1, 2, 3, 4, 5];

  const getColor = (value) => {
    if (value === 1 || value === 2) {
      return '#8e24aa'; // Original purple for Disagree
    }
    if (value === 4 || value === 5) {
      return '#43a047'; // Original green for Agree
    }
    return '#b0bec5'; // Original gray for Neither Agree nor Disagree
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    if (onAnswer) {
      onAnswer(parseInt(value, 10));
    }
  };

  return (
    <Paper 
      elevation={3}
      sx={{ 
        textAlign: 'center', 
        marginBottom: { xs: 3, sm: 4, md: 5 }, 
        p: { xs: 2, sm: 3, md: 4 }, 
        borderRadius: 2,
        backgroundColor: 'rgba(18, 18, 18, 0.8)',
        border: isActive ? '2px solid #B388FF' : '1px solid rgba(224, 224, 224, 0.2)',
        boxShadow: isActive ? '0 0 20px rgba(179, 136, 255, 0.3)' : 'none',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Typography 
        variant="h6" 
        sx={{ 
          marginBottom: { xs: 2, sm: 3 }, 
          fontWeight: 'bold', 
          fontSize: { xs: '1.1rem', sm: '1.3rem', md: '1.5rem' },
          color: '#FFFFFF',
          textShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          letterSpacing: '0.5px',
          lineHeight: 1.4,
        }}
      >
        {question}
      </Typography>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          gap: { xs: 1, sm: 2 },
        }}>
          {isFullScreen && (
          <Typography 
            variant="body1" 
            sx={{ 
              color: '#8e24aa',
              fontWeight: 'bold',
              fontSize: { xs: '0.75rem', sm: '0.9rem', md: '1rem' },
              width: '80px',
              textAlign: 'right',
              textShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
            }}
          >
            Strongly Disagree
          </Typography>)}
          <Box sx={{ position: 'relative' }}>
            <FormControl component="fieldset">
              <RadioGroup 
                row 
                aria-label={question} 
                name={question} 
                value={selectedValue}
                onChange={handleChange}
                sx={{
                  justifyContent: 'center',
                  gap: '48px',
                  '& .MuiFormControlLabel-root': {
                    margin: 0,
                    width: { xs: 24, sm: 28, md: 32 },
                  },
                }}
              >
                {scale.map((value) => (
                  <FormControlLabel
                    key={value}
                    value={value.toString()}
                    control={
                      <Radio
                        sx={{
                          padding: 0,
                          '&.Mui-checked': {
                            color: getColor(value),
                          },
                          '&:not(.Mui-checked)': {
                            color: getColor(value),
                            opacity: .5,
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: { xs: 24, sm: 28, md: 32 },
                          },
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                          },
                        }}
                      />
                    }
                    label=""
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Box 
              sx={{ 
                display: 'flex',
                position: 'absolute',
                bottom: -20,
                left: 0,
                right: 0,
                justifyContent: 'center',
                gap: '48px',
                '& > *': {
                  width: { xs: 24, sm: 28, md: 32 },
                  margin: 0,
                },
              }}
            >
              {scale.map((value) => (
                <Typography
                  key={value}
                  variant="caption"
                  sx={{
                    color: getColor(value),
                    fontSize: { xs: '0.7rem', sm: '0.8rem' },
                    opacity: selectedValue === value.toString() ? 1 : 0.7,
                    textAlign: 'center',
                  }}
                >
                  {value}
                </Typography>
              ))}
            </Box>
          </Box>
          {isFullScreen && (
          <Typography 
            variant="body1"
            sx={{ 
              color: '#43a047',
              fontWeight: 'bold',
              fontSize: { xs: '0.75rem', sm: '0.9rem', md: '1rem' },
              width: '80px',
              textAlign: 'left',
              textShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
            }}
          >
            
            Strongly Agree
          </Typography>)}
        </Box>
      </Box>
    </Paper>
  );
};

export default Question;
